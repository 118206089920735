/**
 * get list: getlist, getlist success, getlist fail
 */

export const GETUSERLIST = "HEADERMANAGEMENT/GETUSERLIST";
export const GETUSERLIST_SUCCESS = "HEADERMANAGEMENT/GETUSERLIST_SUCCESS";
export const GETUSERLIST_FAIL = "HEADERMANAGEMENT/GETUSERLIST_FAIL";

export const GETUSERBYID = "HEADERMANAGEMENT/GETUSERBYID";
export const GETUSERBYID_SUCCESS = "HEADERMANAGEMENT/GETUSERBYID_SUCCESS";
export const GETUSERBYID_FAIL = "HEADERMANAGEMENT/GETUSERBYID_FAIL";

export const SIGNIN = "HEADERMANAGEMENT/SIGNIN";
export const SIGNIN_SUCCESS = "HEADERMANAGEMENT/SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "HEADERMANAGEMENT/SIGNIN_FAIL";

export const SIGNUP = "HEADERMANAGEMENT/SIGNUP";
export const SIGNUP_SUCCESS = "HEADERMANAGEMENT/SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "HEADERMANAGEMENT/SIGNUP_FAIL";

export const UPDATEINFOUSER = "HEADERMANAGEMENT/UPDATEINFOUSER";
export const UPDATEINFOUSER_SUCCESS = "HEADERMANAGEMENT/UPDATEINFOUSER_SUCCESS";
export const UPDATEINFOUSER_FAIL = "HEADERMANAGEMENT/UPDATEINFOUSER_FAIL";

export const GETUSERINFOBYPUBLICKEY = "HEADERMANAGEMENT/GETUSERINFOBYPUBLICKEY";
export const GETUSERINFOBYPUBLICKEY_SUCCESS = "HEADERMANAGEMENT/GETUSERINFOBYPUBLICKEY_SUCCESS";
export const GETUSERINFOBYPUBLICKEY_FAIL = "HEADERMANAGEMENT/GETUSERINFOBYPUBLICKEY_FAIL";

export const UPDATEMESSAGEERRORINIT = "HEADERMANAGEMENT/UPDATEMESSAGEERRORINIT";
